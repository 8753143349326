// HSLA REGEX: hsla\((\d{1,3}), (\d{1,2}), (\d{1,2}), (\d\.\d{2})\)
export const theme = {
  colors: {
    mono: {
      "100": `hsla(36, 3%, 98%, 1)`,
      "200": `hsla(36, 7%, 95%, 1)`,
      "300": `hsla(36, 3%, 90%, 1)`,
      "400": `hsla(25, 6%, 74%, 1)`,
      "500": `hsla(25, 4%, 59%, 1)`,
      "600": `hsla(25, 4%, 39%, 1)`,
      "700": `hsla(25, 3%, 20%, 0.9)`,
      "800": `hsla(25, 3%, 18%, 1)`,
      "900": `hsla(25, 3%, 4%, 1)`,
      chamLight: `hsla(0, 0%, 95%, 0.75)`,
      chamDark: `hsla(0, 0%, 5%, 0.55)`,
    },
    primary: {
      spruce: {
        "100": `hsla(148, 43%, 88%, 1)`,
        "200": `hsla(148, 31%, 67%, 1)`,
        "300": `hsla(160, 45%, 35%, 1)`,
        "400": `hsla(160, 55%, 20%, 1)`,
        "500": `hsla(160, 40%, 15%, 1)`,
      },
      pool: {
        "100": `hsla(195, 42%, 95%, 1)`,
        "200": `hsla(195, 43%, 82%, 1)`,
        "300": `hsla(195, 30%, 40%, 1)`,
        "400": `hsla(195, 55%, 21%, 1)`,
        "500": `hsla(195, 30%, 18%, 1)`,
      },
      flame: {
        "100": `hsla(20, 99%, 95%, 1)`,
        "200": `hsla(25, 84%, 84%, 1)`,
        "300": `hsla(25, 90%, 50%, 1)`,
        "400": `hsla(30, 100%, 28%, 1)`,
        "500": `hsla(30, 26%, 13%, 1)`,
      },
      lemon: {
        "100": `hsla(36, 100%, 97%, 1)`,
        "200": `hsla(43, 91%, 88%, 1)`,
        "300": `hsla(43, 100%, 66%, 1)`,
        "400": `hsla(40, 100%, 30%, 1)`,
        "500": `hsla(40, 78%, 11%, 1)`,
      },
      violet: {
        "100": `hsla(266, 30%, 94%, 1)`,
        "200": `hsla(266, 35%, 83%, 1)`,
        "300": `hsla(266, 27%, 46%, 1)`,
        "400": `hsla(268, 27%, 30%, 1)`,
        "500": `hsla(258, 43%, 15%, 1)`,
      },
      crimson: {
        "100": `hsla(13, 83%, 93%, 1)`,
        "200": `hsla(13, 89%, 83%, 1)`,
        "300": `hsla(13, 71%, 51%, 1)`,
        "400": `hsla(13, 85%, 27%, 1)`,
        "500": `hsla(13, 76%, 14%, 1)`,
      },
    },
  },
  typography: {
    family: {
      body: {
        sansSerif: `"Ideal Sans SSm A", "Ideal Sans SSm B", "Ideal Sans", "Trebuchet MS", sans-serif`,
        serif: `"Mercury SSm A", "Mercury SSm B", Georgia, serif`,
      },
      display: {
        serif: `"Mercury Display A", "Mercury Display B", Georgia, serif`,
        blackletter: `"Inkwell Blackletter A", "Inkwell Blackletter B", script`,
      },
      smallCaps: {
        sansSerif: `"Ideal Sans SSm Small Caps A", "Ideal Sans SSm Small Caps B", "Ideal Sans", "Trebuchet", sans-serif`,
      },
    },
    size: {
      small: "0.875em",
      base: "1em",
      large: "1.25em",
    },
  },
}
