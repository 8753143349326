import React from "react"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { SiteHeader } from "./header"
import { theme } from "../global-theme"

const Site = styled.div`
  display: flex;
  flex-direction: column;
`

const Global = createGlobalStyle`
  html, body {
    height: 100%;
  }
  body {
    background: ${({ theme: { colors }, isLogoHovered }) =>
      isLogoHovered ? colors.mono["100"] : colors.primary.spruce["300"]};
    display: flex;
    margin: 0;
    flex-direction: column;
    transition: background 0.3s ease-in-out;

    #gatsby-focus-wrapper {
      height: 100%;
    }
  }

  #profile-picture {
    float: left;
  }
`

const MainWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.mono["100"]};
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr 3.75fr;
  grid-column-gap: 1em;
  grid-template-rows: 1fr 2.5em;
  grid-template-areas:
    "header main"
    "footer footer";
  margin: 6px;
  padding: 0 15vw;
  min-height: calc(100vh - 10px - 0.125em);

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 2.5em;
    grid-template-areas:
      "header"
      "main"
      "footer";
    padding: 5vw;
  }
`

const Main = styled.main`
  color: ${({ theme: { colors } }) => colors.mono["700"]};
  font-family: ${({ theme: { typography } }) => typography.family.body.serif};
  line-height: 1.375;
  flex: 1 1 auto;
  grid-area: main;
  margin: 2em;
  max-width: 36em;
  min-width: 0;

  @media screen and (max-width: 600px) {
    margin: 0.75em;
  }
`

const Footer = styled.footer`
  color: ${({ theme: { colors } }) => colors.mono["500"]};
  display: flex;
  justify-content: center;
  grid-area: footer;
  background-color: ${({ theme: { colors } }) => colors.mono["100"]};
  border-top: thin solid ${({ theme: { colors } }) => colors.mono["300"]};
  font-family: ${({ theme: { typography } }) =>
    typography.family.body.sansSerif};
  font-size: 0.625em;
  margin: 5px;
  padding: 1em 0 0.75em 0;
  overflow: hidden;
`

const Layout = ({ children }) => {
  const [isLogoHovered, setIsLogoHovered] = React.useState(false)
  const handleLogoHover = state => setIsLogoHovered(state)
  return (
    <ThemeProvider theme={theme}>
      <>
        <Global isLogoHovered={isLogoHovered} />
        <Site>
          <MainWrapper>
            <SiteHeader handleLogoHover={handleLogoHover} />
            <Main>{children}</Main>
            <Footer>© {new Date().getFullYear()} Conor Sexton</Footer>
          </MainWrapper>
        </Site>
      </>
    </ThemeProvider>
  )
}

export default Layout
