import React from "react"
import { MDXProvider } from "@mdx-js/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as GlobalElements from "../components/global/global.styles"

const customComponents = {
  h1: GlobalElements.H1,
  h2: GlobalElements.H2,
  h3: GlobalElements.H3,
  p: GlobalElements.Paragraph,
  a: GlobalElements.ExternalLink,
}

const { Link, LittleH3, CTALink } = GlobalElements
const shortcodes = { Link, LittleH3, CTALink }

const StaticPageTemplate = ({
  children,
  pageContext: {
    frontmatter: { title },
  },
}) => {
  return (
    <MDXProvider components={{ ...customComponents, ...shortcodes }}>
      <SEO title={title} />
      <Layout>{children}</Layout>
    </MDXProvider>
  )
}

export default StaticPageTemplate
