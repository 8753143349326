import React from "react"
import { Link } from "gatsby"
import { Header, Nav, Masthead } from "./header.styles"

export const SiteHeader = ({ handleLogoHover }) => {
  const [isLogoHovered, setIsLogoHovered] = React.useState(false)

  React.useEffect(() => {
    handleLogoHover(isLogoHovered)
  }, [isLogoHovered, handleLogoHover])

  return (
    <Header>
      <Nav>
        <Masthead
          to="/"
          onMouseOver={() => setIsLogoHovered(true)}
          onMouseOut={() => setIsLogoHovered(false)}
          onFocus={() => setIsLogoHovered(true)}
          onBlur={() => setIsLogoHovered(false)}
        >
          C
        </Masthead>
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/reading">Reading</Link>
          </li>
        </ul>
      </Nav>
    </Header>
  )
}
