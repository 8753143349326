import styled from "styled-components"
import { Link } from "gatsby"

export const Header = styled.header`
  display: flex;
  border-right: thin solid ${({ theme: { colors } }) => colors.mono["300"]};
  flex-direction: column;
  grid-area: header;
  font-family: ${({ theme: { typography } }) =>
    typography.family.smallCaps.sansSerif};
  padding: 0 2em;
  margin: 2em 0;

  @media screen and (max-width: 600px) {
    border-right: none;
    align-items: flex-start;
    flex-direction: row;
    background-color: ${p =>
      p.isMenuOpen
        ? ({ theme: { colors } }) => colors.primary.spruce["400"]
        : "none"};
    justify-content: space-between;
    padding: 0;
    margin: 0.75em;
  }
`

export const Masthead = styled(Link)`
  color: ${({ theme: { colors } }) => colors.mono["500"]};
  font-family: ${({ theme: { typography } }) =>
    typography.family.display.blackletter};
  font-size: 3em;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
    transform: rotateZ(-4deg);
  }
`
export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  ul {
    padding-left: 0;
    li {
      font-size: 0.875em;
      list-style: none;
      letter-spacing: 0.05em;
      text-transform: lowercase;
      margin: 1.5em 0;
      a {
        display: inline-block;
        color: ${({ theme: { colors } }) => colors.mono["500"]};
        text-decoration: none;
        transition: color 0.15s ease-in-out, transform 0.15s ease-in-out;

        &:hover {
          color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
          transform: translateX(2px);
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: row;
    align-items: baseline;

    ul {
      display: flex;
      position: relative;
      bottom: 5px;
      margin-top: initial;
      li {
        margin-left: 1em;

        a:focus {
          transform: initial;
        }

        a:hover {
          color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
          transform: translateY(-2px);
        }
      }
    }
  }
`
